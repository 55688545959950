<template>
  <div class="">
    <transition>
      <router-view :brandCode="brandCode" :listTitle="listTitle" :lists="lists" :linkpath="linkpath" />
    </transition>
  </div>
</template>

<script>
import {Mixin} from '@/mixins/common'
import axios from 'axios';
import { mapActions } from "vuex"

export default {
  name: 'StoreSelector',
  data() {
    return {
      linkpath: '',
      brands: {},
      brandCode: '',
      listTitle: '',
      lists: {},
      title: '',
      description: ''
    }
  },
  mixins:[Mixin],
  created() {
    this.refresh()
    this.pageInit(this.$route)
  },
  head: {
    title: function () {
      return {
        inner: this.title
      }
    },
    meta: function () {
      return [
        { property: 'og:title', content: this.title, id: 'og-title'},
        { property: 'og:description', content: this.description, id: 'og-desc' },
        { name: 'description', content: this.description, id: 'meta-desc'},
      ]
    },
  },
  methods: {
    ...mapActions([
      'setshareData'
    ]),
    refresh() {
      this.brands = {}
      this.prefecture = ''
      this.lists = {}
      this.listTitle = ''
      this.title = ''
      this.description = ''
    },
    pageInit(route) {
      if(route.params.code) this.brandCode = route.params.code
      this.getData(route)
    },
    async getData(route){
      const url = this.api + '/brands/' + route.params.code + '/stores/pref/' + route.params.prefecture_code

      // console.log(url);

      await axios.get(url)
      .then((res) => {
        this.lists = res.data
        this.brands.code = res.data[0].brand_code
        this.brands.name_en = res.data[0].brand_name_en
        this.listTitle = res.data[0].prefecture_name_ja + 'の店舗'

        // meta
        let title = ''

        if(this.brandCode !== ''){
          this.linkpath = '/brand/' + route.params.code + '/stores/' + route.params.prefecture_code+ '/'

          title += res.data[0].brand_name_full + 'の'
        }

        title += this.listTitle + '一覧'

        this.title = title + ' [市区町村別]'
        this.description = title + 'ページ。'
        if(this.brandCode !== '') this.description += res.data[0].brand_name_en + 'の'
        this.description += res.data[0].prefecture_name_ja + 'のお店を市区町村別に探すことができます。'
        this.$emit('updateHead')

        // sharedata
        const shareData = {}
        shareData.title = this.title
        shareData.text = this.title
        shareData.url = window.location.href
        this.setshareData(shareData)


      })
      .catch((e) =>{
        // console.log(e);
        // console.log('item detail not found')
      })
    },

  }

}

</script>

<style>
.list_title{
  background-color: #FFF;
  font-size: 1rem;
  padding: 16px 24px;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
}

</style>
